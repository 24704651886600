






















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import obuFiled from './obuFiled.vue';
import pharmaFiled from './pharmaFiled.vue';
import { ISearchConfig } from '@/components/SearchTable/index.d';
import { UserUploadService } from '@/services/user-uploading';
import novaEmpty from './compoennts/nova-empty.vue';
import { pharmaTableConfig, searchConfig } from './index.config';
import { cloneDeep, assign, isArray } from 'lodash';

@Component({
  components: { pharmaFiled, obuFiled, novaEmpty }
})
export default class Manager extends Vue {
  public loading: boolean = false;
  public activeName: string = 'pharma';
  public searchText: string = '';
  public treeList: any[] = [];
  public treeloading: boolean = false;
  public tableConfig: any[] = pharmaTableConfig;
  public table: any[] = [];
  public isShowDropMenu: boolean = false;
  @Prop({ required: true })
  public uploadStatus!: any;
  private searchConfig: ISearchConfig[] = [];
  private appNameOptions: any[] = [];
  private applicationIds: any[] = [];
  private appNameTags: any[] = [];
  private formSearch: any = {
    division: '0'
  };

  private props: any = {
    label: 'label',
    children: 'children',
    isLeaf: this.isLeaf
  };
  @Inject(UserUploadService) private userUploadService!: UserUploadService;
  private uploadResult: Dict<any> = {};
  @Watch('filterText')
  public onChangeFilterText(val: any): void {
    (this.$refs as any).tree.filter(val);
  }

  // 选择应用访问权限
  public selectChannel(val: any): void {
    this.formSearch = val;
    this.applicationIds = val.applicationIds;
  }

  get nameTags(): any[] {
    return this.appNameTags.slice(0, 2) || [];
  }

  // 获取上传列表
  public async getList(val?: any): Promise<void> {
    if (!val) {
      val = {
        division: '0'
      };
    }
    try {
      this.loading = true;
      const result = await this.userUploadService.getuploadsTable(val);
      if (!result) {
        this.$notify.error({
          title: '错误',
          message: '获取列表失败，请您重试。'
        });
        return;
      } else {
        result.forEach((element: any) => {
          if (element.applicationNames) {
            let appNameTags = element.applicationNames.split(',');
            element.applicationNames = appNameTags;
            element.nameTags = appNameTags.slice(0, 2) || [];
          }
        });
      }
      this.table = result;

    } finally {
      this.loading = false;
    }
  }

  // 搜索后获取列表
  public async getfieldList(val?: any): Promise<void> {
    this.formSearch = val;
    this.formSearch.division = '0';
    try {
      this.loading = true;
      const result = await this.userUploadService.getuploadsTable(
        assign({ division: '0' }, val)
      );
      if (!result) {
        this.$notify.error({
          title: '错误',
          message: '获取列表失败，请您重试。'
        });
        return;
      } else {
        result.forEach((element: any) => {
          if (element.applicationNames) {
            let appNameTags = element.applicationNames.split(',');
            element.applicationNames = appNameTags;
            element.nameTags = appNameTags.slice(0, 2) || [];
          }
        });
      }
      this.table = result;
    } finally {
      this.loading = false;
    }
  }

  public async getUploadStatus(val: string): Promise<void> {
    (this.$parent.$parent.$parent.$parent as any).getUploadStatus(val);
  }

  @Watch('uploadStatus', { deep: true, immediate: true })
  public onChangeUploadStatus(val: any): void {
    this.uploadResult = val;
    const { status } = val;
    if (status && status !== 'I') {
      this.getList();
    }
  }

  public filterNode(value: string, data: any): boolean {
    if (!value) {
      return true;
    }
    return data.label.indexOf(value) !== -1;
  }

  // private successdownload() {}
  // private successupload() {}

  // tree节点选择
  public handleNodeClick(data: any): void {
    // 切换清空form
    this.formSearch = { division: '0' };
    const { treeLevel, label } = data;
    // 如果是最外层全部 清空form treeNodeLevel
    if (treeLevel !== 'top' && treeLevel) {
      this.formSearch.treeNodeLevel = treeLevel;
      this.formSearch.position = label;
    } else {
      this.formSearch.treeNodeLevel = '';
      this.formSearch.position = '';
    }
    this.getList(this.formSearch);
  }

  // tree搜索
  public async queryTree(val?: string): Promise<void> {
    this.treeloading = true;
    if (!val) {
      this.getTree();
      this.treeloading = false;
      return;
    }
    const res = await this.userUploadService.getuserpharma({
      keyword: val
    });
    if (res && Array.isArray(res)) {
      this.treeList = res;
    }
    this.treeloading = false;
  }

  public mounted(): void {
    this.initData();
    this.getTree();
  }

  private async getTree(): Promise<void> {
    this.treeloading = true;
    const res = await this.userUploadService.getuserpharmatree();
    if (res && isArray(res)) {
      console.log(res);

      this.treeList = [
        { label: '全部', treeLevel: 'top', value: 1, children: res }
      ];
    }
    this.treeloading = false;
  }

  private isLeaf(data: any, node: any): boolean {
    if (data.children && data.children.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  private async initData(): Promise<void> {
    this.searchConfig = cloneDeep(searchConfig);
    try {
      this.searchConfig[4].options =
        (await this.userUploadService.getpromotionGridsList('0')) || [];
      this.searchConfig[2].options =
        (await this.userUploadService.getprofilelist()) || [];

      // 应用访问权限
      this.appNameOptions =
        (await this.userUploadService.getApplicationNames({
          divisionCode: '1'
        })) || [];
      this.searchConfig[5].options = [];
      if (this.searchConfig[5].options.length === 0) {
        this.appNameOptions.forEach((item: any) => {
          (this.searchConfig[5].options as any).push({
            value: item.id,
            label: item.channelName
          });
        });
      }
      if (this.uploadResult.status !== 'I') {
        this.getList();
      }
    } finally {
      this.loading = false;
    }
  }

  private loadNode(node: any, resolve: any) {
    setTimeout(() => {
      const data = node.data.children;
      resolve(data);
    }, 500);
  }
}
