














import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { UserUploadService } from '@/services/user-uploading';

@Component
export default class Manager extends Vue {
  @Prop({ required: true })
  private uploadFile!: UserUploadService;

  @Prop({ required: true })
  private serviceName!: string;

  @Prop({ required: true })
  private division!: string;

  @Prop({required: true})
  private disabled!: boolean;

  // i8n
  @Inject(I18nService)
  private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }
  @Inject(UserUploadService) private userUploadService!: UserUploadService;

   validateSuffix(str:string) {
     const validSuffixes = [".xlsx", ".xls", ".csv", ".xlsb"];
     for (let i = 0; i < validSuffixes.length; i++) {
       if (str.endsWith(validSuffixes[i])) {
         return true;
       }
     }
     return false;
  }

  private async fileChange(file: any) {
    console.log('Event', file);
    if (!this.validateSuffix(file.name)) {
      this.$notify.error({
        title: '错误',
        message: '文件格式错误，请选择正确格式的文件',
      });
      return;
    }

    var params: {} =
      this.division == '0' ? { type: 'syncPharma' } : { type: 'syncOnco' };
    const res = await this.userUploadService.getsynchronization(params);
    if (res.status == 1) {
      this.$message({
        message: '当前有用户执行同步操作，请稍后重试'
      });
    } else {
      let formData = new FormData();
      formData.append('file', file.raw);
      const loading = this.$loading({
        lock: true,
        text: '上传人员列表中，请稍后...'
      });
      try {
        const res = await this.uploadFile[this.serviceName](
          formData,
          this.division
        );
        if (!res) {
          this.$notify.error({
            title: '错误',
            message: '文件上传失败'
          });
          return;
        }
        const { status } = res;
        if (status === 'Y') {
          this.$emit('uploadsuccess');
          this.uploadFile.setVal(res.success.createTime);
          this.$notify.success({
            title: '成功',
            message: '文件上传成功' + res.updateCount + '条数据已更新'
          });
        } else if (status === 'N') {
          this.$emit('updateStatus', this.division);
          this.$notify.error({
            title: '错误',
            message: '文件上传失败'
          });
        }
      } finally {
        loading.close();
      }
    }
  }
}
