




















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { UserUploadService } from '@/services/user-uploading';
import novaUpload from './novaUpload.vue';
import moment from 'moment';

@Component({
  components: {
    novaUpload
  }
})
export default class Manager extends Vue {
  public loading: boolean = false;
  @Prop({ default: 'error' })
  private type!: string;

  @Prop({ required: true })
  private division!: string;

  @Prop({ required: true, default: () => ({}) })
  private uploadStatus!: any;

  @Inject(UserUploadService) private userUploadService!: UserUploadService;

  // i8n
  @Inject(I18nService)
  private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  private async downFile() {
    if (this.uploadStatus.failure.fileId) {
      const date = new Date();
      try {
        this.loading = true;
        await this.userUploadService.downstafflist(
          this.uploadStatus.failure.fileId,
          `error_${this.userUploadService.getFileNamePrefix(
            this.division
          )}${moment(date).format('YYYYMMDDHHmm')}.xlsx`
        );
      } finally {
        this.loading = false;
      }
    } else {
      this.$notify.error({
        title: '错误',
        message: '文件下载失败'
      });
    }
  }

  private uploadsuccess() {
    (this.$parent.$parent as any).getList();
    (this.$parent.$parent as any).getUploadStatus(this.division);
  }

  private updateStatus(val: any) {
    (this.$parent.$parent as any).getUploadStatus(this.division);
  }

  private downTemp() {
    const date = new Date();
    try {
      this.loading = true;
      this.userUploadService.downstaffTemp(
        this.division,
        `${this.userUploadService.getFileNamePrefix(this.division)}.xlsx`
      );
    } finally {
      this.loading = false;
    }
  }
}
