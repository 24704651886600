import { I18nService } from '@cds/i18n';
import { ISearchConfig } from '@/components/SearchTable/index.d';

export const pharmaTableConfig = [
  {
    prop: 'name',
    label: '用户'
  },
  {
    prop: 'personnelNumber',
    label: '员工号'
  },
  {
    prop: 'profileNames',
    label: '角色'
  },
  {
    prop: 'email',
    label: 'E-mail'
  },
  {
    prop: 'post',
    label: 'POST'
  },
  {
    prop: 'promotionGrids',
    label: 'promotiongrid'
  },
  // {
  //   prop: 'applicationNames',
  //   label: '应用访问权限'
  // }
];

export const searchConfig: ISearchConfig[] = [
  {
    type: 'input',
    key: 'name',
    category: 'common_content',
    subclass: 'user'
  },
  {
    type: 'input',
    key: 'personnelNumber',
    category: 'common_content',
    subclass: 'id'
  },
  {
    type: 'select',
    multiple: true,
    key: 'profileNames',
    category: 'common_content',
    subclass: 'role',
    options: []
  },
  {
    type: 'input',
    key: 'email',
    category: 'common_content',
    subclass: 'email'
  },
  {
    type: 'select',
    multiple: true,
    key: 'promotionGrids',
    category: 'background_mgmt',
    subclass: 'promotionGrid',
    options: []
  },
  {
    type: 'select',
    multiple: true,
    key: 'applicationIds',
    category: 'common_content',
    subclass: 'app_authority',
    options: []
  }
];
