




























































import { Component, Ref, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import obuFiled from './obuFiled.vue';
import pharmaFiled from './pharmaFiled.vue';
import { UserUploadService } from '@/services/user-uploading';
import { UserGroupService } from '@/services/user-group-service';
import novaUpload from './compoennts/novaUpload.vue';
import { I18nService } from '@cds/i18n';
import moment from 'moment';
import { ElTabs } from 'element-ui/types/tabs';
import { throttle } from 'lodash';

@Component({
  components: { pharmaFiled, obuFiled, novaUpload }
})
export default class Manager extends Vue {
  public fileId!: number;
  public id!: string;
  public createTime: string = '';
  private activeName: string = '0';
  private isUploadDisable: boolean = true;
  private loading: boolean = false;
  private uploadStatus: any = {};
  private targetLeftBar!: HTMLElement;
  private resizeLine!: HTMLElement;
  public salesdivision:any =[];
  @Ref('tabsEl') private tabsEl!: ElTabs;
  @Inject(UserUploadService) private userUploadService!: UserUploadService;
  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  private targetLeftBarResizeHandlerProxy = throttle((event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.targetLeftBarResizeHandler(event);
  }, 200);

  // i8n
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }
  public async created(): Promise<void> {
    this.getsetup();
  }
   //获取配置文件
  public async getsetup(){
    let res: any = await this.userGroupService.getsetup();
    this.salesdivision = res
  }
  //禁用按钮提示判断
  public async disableTips() {
    // this.getSyncStatus(this.activeName);
    var params: {} =
    this.activeName == '0' ? { type: 'syncPharma' } : { type: 'syncOnco' };
    const res = await this.userUploadService.getsynchronization(params);
    this.isUploadDisable = res.status === 0 ? false:true
    if (this.isUploadDisable === false) {
      // return;
      if(res.status == 1){
        this.$notify.error({
          title:'当前有人进行同步操作' ,
          message:''
        })
      }else{
        return;
      }
    } else {
      const h = this.$createElement;
      console.log('h', h);
      this.$notify.error({
        title: 'Field Force同步开关已开启',
        message: h(
          'a',
          { class: 'linkStyle', attrs: { href: this.toOther() } },
          '点击前往同步页面关闭后可上传'
        )
      });
    }
  }

  //去其他页面的判断
  private toOther() {
    console.log('activeName', this.activeName);
    if (this.activeName === '0') {
      return '/pharmafield';
    } else {
      return '/oncologyfield';
    }
  }

  public handleUpdateTable(): void {
    const type = this.activeName === '0' ? 'pharmaFiled' : 'ONCOFiled';
    (this.$refs[type] as any).getList();
    this.getUploadStatus(this.activeName);
    this.createTime = this.userUploadService.getVal()
      ? '(' + this.userUploadService.getVal() + '  最后更新)'
      : '';
  }

  public updateStatus(val: any): void {
    this.getUploadStatus(val, true);
  }

  public async toggleTab(val: any): Promise<void> {
    console.log('toggleTab');
    await this.getUploadStatus(this.activeName);
    await this.getSyncStatus(this.activeName);
    this.initResizeLeftBar();
  }

  public successdownload(): void {
    const date = new Date();
    if (
      this.uploadStatus &&
      this.uploadStatus.success &&
      this.uploadStatus.success.fileId
    ) {
      console.log(' this.uploadStatus.success.fileId', this.activeName)
      this.userUploadService.downstafflist(
        this.uploadStatus.success.fileId,
        `${this.userUploadService.getFileNamePrefix(this.activeName)}${moment(
          date
        ).format('YYYYMMDDHHmm')}.xlsx`
      );
    } else {
      this.userUploadService.downstaffTemp(
        this.activeName,
        `${this.userUploadService.getFileNamePrefix(this.activeName)}${moment(
          date
        ).format('YYYYMMDDHHmm')}.xlsx`
      );
    }
  }

  public destroyed(): void {
    this.removeListener();
  }

  public async mounted(): Promise<void> {
    await this.$nextTick();
    this.activeName = (this.tabsEl as any).panes[0].name;
    await this.getSyncStatus(this.activeName);
    this.getUploadStatus(this.activeName);
    this.initResizeLeftBar();
  }

  //pharma和onco 当前同步状态判断（用于禁用上传按钮）
  private async getSyncStatus(val: any) {
    // this.isUploadDisable = false;
    this.isUploadDisable = true;
    this.loading = true;
    var params: {} = val == '0' ? { type: 'syncPharma' } : { type: 'syncOnco' };
    const res = await this.userUploadService.getsynchronization(params);
    //当前数据正在同步中的处理
    //result  0未同步1同步中2已同步
    if (res.status === 0) {
      this.isUploadDisable = false;
      console.log('this.syncUploadStatus', this.isUploadDisable);
    } else {
      this.isUploadDisable = true;
    }
    this.loading = false;
  }

  private async getUploadStatus(val: string, isAfterUploaded?: boolean) {
    // 先获取上传状态
    console.log('---val-----', val, 'isAfterUploaded', isAfterUploaded);
    const res = await this.userUploadService.getuploadstatus(val);
    if (!res) {
      this.$notify.error({
        title: '错误',
        message: '获取上传状态失败，请您重试。'
      });
    } else if (res.success) {
      console.log('-------------', res);
      this.createTime = res.success.createTime
        ? '(' + res.success.createTime + '  最后更新)'
        : '';
    }
    res.isAfterUploaded = isAfterUploaded;
    this.uploadStatus = res || {};
  }

  private initResizeLeftBar(): void {
    this.removeListener();
    setTimeout(
      (() => {
        this.targetLeftBar = this.tabsEl.$el.querySelector(
          'div.staffdataupload_leftbar'
        ) as HTMLElement;
        this.resizeLine = this.targetLeftBar.querySelector(
          'div.resize-line'
        ) as HTMLElement;
        if (this.resizeLine) {
          this.resizeLine.addEventListener(
            'mousedown',
            this.addResizeLineMousemoveListener
          );
          document.addEventListener(
            'mouseup',
            this.removeResizeLineMousemoveListener
          );
        }
      }).bind(this)
    );
  }

  private addResizeLineMousemoveListener(event: any): void {
    document.addEventListener(
      'mousemove',
      this.targetLeftBarResizeHandlerProxy
    );
  }
  private removeResizeLineMousemoveListener(event: any): void {
    document.removeEventListener(
      'mousemove',
      this.targetLeftBarResizeHandlerProxy
    );
  }

  private targetLeftBarResizeHandler(event: any): void {
    const rect = this.targetLeftBar.getBoundingClientRect();
    this.targetLeftBar.style.width =
      rect.width - (rect.right - event.clientX) + 5 + 'px';
  }
  private removeListener(): void {
    if (this.resizeLine) {
      this.resizeLine.removeEventListener(
        'mousedown',
        this.addResizeLineMousemoveListener
      );
      this.resizeLine.removeEventListener(
        'mouseup',
        this.removeResizeLineMousemoveListener
      );
    }
  }
}
